<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Salida de producto</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Products -->
        <div>
          <label class="text-sm opacity-75">Producto</label>
          <v-select :options="products" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="input.product" class="mb-4 md:mb-0" name="product" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('product')">{{ errors.first('product') }}</span>
        </div>

        <!-- Warehouse -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Almacén</label>
          <v-select value="value" label="name" :options="warehouses" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="input.warehouse" class="mb-4 md:mb-0" name="warehouse" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('warehouse')">{{ errors.first('warehouse') }}</span>
        </div>

        <!--Quantity-->
        <vs-input-number min="1" class="mt-5 mb-5" label="Cantidad:" v-model.number="input.quantity" name="quantity"
                         v-validate="'required|min_value:1'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>

        <!-- Motive -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Motivo</label>
          <v-select label="value" :options="motives" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="input.motive" class="mb-4 md:mb-0" name="motive" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('motive')">{{ errors.first('motive') }}</span>
        </div>

        <!-- Observation -->
        <vs-textarea @blur="input.observation= trimString(input.observation)" label="Observación"
                     v-model="input.observation" class="mt-5 w-full"
                     name="observation" v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('observation')">{{ errors.first('observation') }}</span>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'

import { db, FieldValue, auth } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data () {
    return {
      input: {},

      motives: [
        'Reajuste de almácen',
        'Fallas de fábrica',
        'Daño en el producto',
        'Pérdida o robo',
        'Garantía',
        'Comprobante de contingencia'
      ],

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    async isSidebarActive (val) {
      if (val) {
        this.input = {
          quantity: 1
        }
        await this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          let output = {
            products: [
              {
                id: this.input.product.id,
                name: this.input.product.name,
                sku: this.input.product.sku,
                cost: this.input.product.cost,
                quantity: this.input.quantity
              }
            ],
            warehouse: {
              id: this.input.warehouse.id,
              name: this.input.warehouse.name
            },
            motive: this.input.motive,
            observation: this.input.observation,
            origin: 'Web',
            userName: auth.currentUser.displayName,
            currency: {
              id: 1,
              value: 'Soles',
              symbol: 'S/',
              code: 'PEN'
            }
          }
          // New input
          const doc = await db.collection('outputs').add({
            ...output,
            uid: auth.currentUser.uid,
            createdAt: FieldValue.serverTimestamp()
          })
          output.id = doc.id
          output.createdAt = new Date()
          this.$emit('add', output)
          this.$emit('closeSidebar')
          this.$vs.notify({
            color: 'success',
            title: 'Salida',
            text: 'Salida registrada correctamente.'
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
